import React from 'react'
import Head from 'next/head'
import {
  ClipboardListIcon,
  CurrencyDollarIcon,
  MapIcon
} from '@heroicons/react/outline'
import { NextPage } from 'next'
import { LandingLayout } from '../components/Layout'
import Link from 'next/link'
import heroBG from '../public/heroBG.jpeg'
import Image from 'next/image'

const Home = () => {
  return (
    <div>
      <Head>
        <title>Breeding Market</title>
        <meta
          name="description"
          content="State of the art technology for livestock breeders."
        />
        <link rel="icon" href="/favicon.ico" />
        <meta name="title" content="Breeding Market" />
        <meta
          name="description"
          content="State of the art technology for livestock breeders."
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://breedingmarket.com/" />
        <meta property="og:title" content="Breeding Market" />
        <meta
          property="og:description"
          content="State of the art technology for livestock breeders."
        />
        <meta
          property="og:image"
          content="https://breedingmarket.com/meta.png"
        />
      </Head>

      <main className="mt-16 sm:mt-24">
        <Hero />
        <ActionSection />
      </main>
    </div>
  )
}

Home.getLayout = function getLayout(page: NextPage) {
  return (
    <LandingLayout showDots={true} showLogin={true}>
      {page}
    </LandingLayout>
  )
}

export default Home

const Hero = () => {
  return (
    <div className="mx-auto max-w-7xl px-4 text-center pb-20">
      <div className="text-4xl tracking-tight font-extrabold text-neutral-900 sm:text-5xl md:text-6xl">
        <span className="block xl:inline font-display">The future hub of</span>{' '}
        <span className="block text-indigo-600 xl:inline">
          livestock commerce
        </span>
      </div>
      <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
        Starting with sheep, we're improving the outdated technology breeders
        use.
      </p>
      <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
        Check out some of our first products.
      </p>
      <div className="mt-5 mx-auto sm:flex sm:justify-center md:mt-8 space-y-2 sm:space-y-0">
        <div className="rounded-md shadow">
          <Link href={'/directory'} passHref>
            <a className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10">
              Sheep Breeder Directory
            </a>
          </Link>
        </div>
        <div className="rounded-md shadow sm:mt-0 sm:ml-3">
          <Link href={'/previous'} passHref>
            <a className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10">
              Online Sales Analysis
            </a>
          </Link>
        </div>
      </div>
    </div>
  )
}

/* This example requires Tailwind CSS v2.0+ */

const supportLinks = [
  {
    name: 'Livestock Delivery Tools',
    href: '#',
    description:
      'Need to transport some animals? See the routes of livestock haulers and connect with them directly.',
    icon: MapIcon
  },
  {
    name: 'Upcoming Auctions Schedule',
    href: '#',
    description: 'Stay up to date on the latest in person and online auctions.',
    icon: ClipboardListIcon
  },
  {
    name: 'Livestock Payments',
    href: '#',
    description: 'A new way to do business online with other breeders.',
    icon: CurrencyDollarIcon
  }
]

const ActionSection = () => {
  return (
    <div className="bg-white">
      {/* Header */}
      <div className="relative pb-32 bg-gray-800">
        <div className="absolute inset-0">
          <Image
            src={heroBG}
            alt="hero background"
            objectFit="cover"
            layout="fill"
          />
          <div
            className="absolute inset-0 bg-gray-800 mix-blend-multiply"
            aria-hidden="true"
          />
        </div>
        <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-extrabold tracking-tight text-white md:text-5xl lg:text-6xl">
            Coming Soon
          </h1>
          <p className="mt-6 max-w-3xl text-xl text-gray-300">
            Here's a sneak peek of what is in the works.
          </p>
        </div>
      </div>

      {/* Overlapping cards */}
      <section
        className="-mt-32 max-w-7xl mx-auto relative z-10 pb-32 px-4 sm:px-6 lg:px-8"
        aria-labelledby="contact-heading"
      >
        <div className="grid grid-cols-1 gap-y-20 lg:grid-cols-3 lg:gap-y-0 lg:gap-x-8">
          {supportLinks.map((link) => (
            <div
              key={link.name}
              className="flex flex-col bg-white rounded-2xl shadow-xl"
            >
              <div className="flex-1 relative pt-16 px-6 pb-8 md:px-8">
                <div className="absolute top-0 p-5 inline-block bg-indigo-600 rounded-xl shadow-lg transform -translate-y-1/2">
                  <link.icon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </div>
                <h3 className="text-xl font-medium text-gray-900">
                  {link.name}
                </h3>
                <p className="mt-4 text-base text-gray-500">
                  {link.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  )
}
